<template>
  <div>
    <header class="header">
      <div class="header_box">
        <div class="logo">
          <router-link :to="{ name: 'brand/home' }">
            <svg
              width="120"
              height="40"
              viewBox="0 0 120 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M56.6146 10.9185H50.5898V29.7415H54.7216V23.493H56.7293C60.3533 23.493 63.5143 21.52 63.5143 17.0426C63.5188 12.3851 60.3989 10.9185 56.6146 10.9185ZM56.5251 19.923H54.7252V14.4845H56.4096C58.394 14.4845 59.5017 15.1422 59.5017 17.0396C59.4981 18.8873 58.5301 19.923 56.5215 19.923H56.5251Z"
                fill="white"
              />
              <path
                d="M69.624 10.9185H65.4922V29.7415H76.2944V25.9466H69.6249L69.624 10.9185Z"
                fill="white"
              />
              <path
                d="M99.1238 18.7708H99.0343C98.5498 17.3042 96.2189 10.9185 96.2189 10.9185H91.8311L97.0007 23.0861V29.7415H101.108V23.088L106.302 10.9204H101.987C101.987 10.9204 99.6083 17.3042 99.1238 18.7708Z"
                fill="white"
              />
              <path
                d="M112.523 25.9446L119.931 13.6248V10.9185H108.161V14.6616H114.854L107.445 27.0063V29.7415H120V25.9466L112.523 25.9446Z"
                fill="white"
              />
              <path
                d="M38.6403 10.9185L33.3086 29.7415H37.5084L40.9712 14.8068L44.5711 29.7415H48.9097L43.5771 10.9185H38.6403Z"
                fill="white"
              />
              <path
                d="M41.1102 25.6749C40.7497 25.6805 40.3988 25.8045 40.1015 26.0312C39.8042 26.2579 39.5739 26.5772 39.4395 26.9489C39.305 27.3206 39.2725 27.7282 39.346 28.1204C39.4194 28.5126 39.5956 28.8719 39.8523 29.1532C40.1091 29.4344 40.4349 29.6251 40.7889 29.7012C41.1429 29.7773 41.5092 29.7354 41.8418 29.5808C42.1744 29.4262 42.4585 29.1658 42.6583 28.8323C42.8581 28.4989 42.9647 28.1072 42.9648 27.7066C42.9631 27.4379 42.9138 27.1722 42.8197 26.9246C42.7256 26.677 42.5886 26.4525 42.4163 26.2638C42.2441 26.0751 42.0401 25.926 41.816 25.825C41.5919 25.7239 41.3521 25.6729 41.1102 25.6749Z"
                fill="white"
              />
              <path
                d="M9.64077 11.4397L13.8495 26.3097L20.5289 15.1828H13.8361V11.4387H21.6402L6.21555 0.692925C5.60187 0.265954 4.89719 0.0276039 4.17348 0.00225683C3.44978 -0.0230903 2.73299 0.165455 2.09633 0.548634C1.46489 0.925871 0.936831 1.48553 0.567861 2.16851C0.198891 2.8515 0.00269572 3.63251 0 4.42908V28.0101L4.69591 11.4297L9.64077 11.4397Z"
                fill="white"
              />
              <path
                d="M28.4498 16.1799L25.5843 14.1899L18.1992 26.471H24.4228L28.4525 23.6661C29.0258 23.2716 29.499 22.721 29.8271 22.0663C30.1553 21.4117 30.3275 20.6748 30.3275 19.9255C30.3275 19.1762 30.1553 18.4393 29.8271 17.7847C29.499 17.13 29.0258 16.5794 28.4525 16.1849L28.4498 16.1799Z"
                fill="white"
              />
              <path
                d="M7.03262 15.3252L3.57069 30.2609H0.00488281V35.4039C0.00738667 36.2005 0.20348 36.9816 0.572471 37.6646C0.941461 38.3477 1.46964 38.9073 2.1012 39.2843C2.73786 39.6674 3.45458 39.8559 4.17823 39.8307C4.90188 39.8056 5.60656 39.5676 6.22042 39.141L18.9766 30.2569H10.6351L7.03262 15.3252ZM7.17053 30.2609C6.81006 30.2552 6.45914 30.1312 6.16188 29.9046C5.86463 29.6779 5.6343 29.3587 5.49985 28.9871C5.3654 28.6154 5.33282 28.2079 5.40621 27.8157C5.4796 27.4235 5.65568 27.0642 5.91232 26.7829C6.16897 26.5016 6.49472 26.3109 6.84865 26.2347C7.20257 26.1585 7.56888 26.2002 7.90151 26.3546C8.23415 26.5091 8.51827 26.7693 8.71818 27.1027C8.91809 27.436 9.02486 27.8276 9.02507 28.2281C9.02343 28.4969 8.97416 28.7627 8.88007 29.0103C8.78598 29.2579 8.64892 29.4825 8.47671 29.6712C8.3045 29.86 8.10051 30.0092 7.8764 30.1104C7.65228 30.2116 7.41243 30.2627 7.17053 30.2609Z"
                fill="white"
              />
              <path
                d="M83.572 10.9185L78.2412 29.7415H82.4419L85.9039 14.8068L89.5037 29.7415H93.8432L88.5115 10.9214L83.572 10.9185Z"
                fill="white"
              />
              <path
                d="M86.0428 25.6749C85.6822 25.6804 85.3312 25.8042 85.0338 26.0307C84.7364 26.2573 84.5059 26.5765 84.3714 26.9482C84.2368 27.3199 84.2041 27.7275 84.2775 28.1198C84.3509 28.5121 84.527 28.8715 84.7837 29.1529C85.0404 29.4342 85.3662 29.625 85.7202 29.7011C86.0742 29.7773 86.4406 29.7355 86.7733 29.5809C87.1059 29.4263 87.3901 29.1659 87.5899 28.8325C87.7897 28.499 87.8964 28.1073 87.8964 27.7066C87.8949 27.4379 87.8457 27.1722 87.7517 26.9247C87.6577 26.6771 87.5207 26.4526 87.3486 26.2639C87.1764 26.0752 86.9725 25.926 86.7484 25.825C86.5244 25.7239 86.2846 25.6729 86.0428 25.6749Z"
                fill="white"
              />
            </svg>
          </router-link>
        </div>
        <div class="right_menu">
          <button @click="linkLogin()">로그인</button>
          <button @click="linkSignUp()">회원가입</button>
        </div>
      </div>
    </header>
    <div class="main cs policy">
      <div class="main_box">
        <h1>이용약관</h1>
        <div class="cs_main">
          <div class="side_depth_box">
            <div class="one_depth">
              <router-link
                class="policy"
                :to="{
                  name: 'HomeService',
                }"
                :class="{ on : this.$route.path === '/brand/stplat/service' }"
              >
                서비스 이용약관
              </router-link>
              <router-link
                class="personal"
                :to="{
                  name: 'HomePrivacy',
                }"
                :class="{ on : this.$route.path === '/brand/stplat/privacy' }"
                >개인정보 처리방침
              </router-link>
              <!-- <router-link
                class="marketing on"
                :to="{
                  name: 'HomeMarketing',
                }"
                >마케팅 정보 수신
              </router-link> -->
            </div>
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import router from "@/router";

export default defineComponent({
  name: 'HomePolicy',

  methods: {
    /**
     * * 로그인 페이지로 이동
     */
    linkLogin () {
      router.push({ name: "brand/login" });
    },

    /**
     * * 회원 가입 페이지로 이동
     */
    linkSignUp () {
      router.push({ name: "brand/sineUp" });
    }
  }
});
</script>

<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/font.css"></style>
